<template>
  <v-dialog v-model="general_journal_entry_validate_dialog" width="550">
    <v-card class="pa-5">
      <v-card-title class="text-h5 mb-3"> Oops! </v-card-title>
      <v-alert color="orange" type="warning" text>{{
        validation_message
      }}</v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="passCloseDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "GeneralJournalEntryValidateDialog",
  props: ["general_journal_entry_validate_dialog", "validation_message"],
  data() {
    return {};
  },
  methods: {
    passCloseDialog() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>
